/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, Link, Typography, Avatar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

//COMPONENTS
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";

import { findBluSensorDetailsNLS } from "./../blusensor/BluSensor";

import { BluSensorUsagePlanDetails } from "./../blusensor/BluSensorUser";

//COMPONENTS
import S3Image from "../aws/S3Image";

//HOOKS
import useAuth from "../../hooks/useAuth";

//LOGO
import Logo from "../Logo";

//ICONS
import CodeIcon from "@mui/icons-material/Code";
import KitchenIcon from "@mui/icons-material/Kitchen";
import BookIcon from "@mui/icons-material/Book";
import FolderIcon from "@mui/icons-material/Folder";
//import SensorsIcon from '@mui/icons-material/Sensors';
//import LocationIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AnnouncementIcon from "@mui/icons-material/Announcement";
//import RouterIcon from '@mui/icons-material/Router';
import ThermostatIcon from "@mui/icons-material/Thermostat";
import ArticleIcon from "@mui/icons-material/Article";
import PeopleIcon from "@mui/icons-material/People";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

//NLS
import { useTranslation } from "react-i18next";

/**********************************************************************
 * PAGE
 ***********************************************************************/
const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation("nls");
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const sections = [
    {
      title: t("common.NLS_OVERVIEW"),
      items: [
        {
          title: t("common.NLS_DASHBOARD"),
          path: "/",
          icon: <DashboardIcon fontSize="small" />,
        },
        {
          title: t("common.NLS_ALARMS"),
          path: "/alarms",
          icon: <AnnouncementIcon fontSize="small" />,
        },
        {
          title: t("common.NLS_REPORTS"),
          path: "/reports",
          icon: <AssessmentIcon fontSize="small" />,
        },

        /*
        {
          title: t('common.NLS_ANALYTICS'),
          path: '/analytics',
          icon: <MonitorHeartIcon fontSize="small" />
        },
        */
      ],
    },
    {
      title: t("common.NLS_MONITORING"),
      items: [
        {
          title: t("common.NLS_FACILITIES"),
          path: "/facilities",
          icon: <BusinessIcon fontSize="small" />,
        },
        {
          title: t("common.NLS_ASSETS"),
          path: "/assets",
          icon: <KitchenIcon fontSize="small" />,
        },
        {
          title: t("common.NLS_THINGS"),
          path: "/things",
          icon: <ThermostatIcon fontSize="small" />,
        },
      ],
    },
    ,
    {
      title: t("common.NLS_DOCUMENTATION"),
      items: [
        {
          title: t("common.NLS_DOCUMENTS"),
          path: "/docbooks",
          icon: <BookIcon fontSize="small" />,
        },
        {
          title: t("common.NLS_FORMS"),
          path: "/docforms",
          icon: <ArticleIcon fontSize="small" />,
        },
        {
          title: t("common.NLS_FILES"),
          path: "/files",
          icon: <FolderIcon fontSize="small" />,
        },
      ],
    },
    {
      title: t("common.NLS_TEAM"),
      items: [
        {
          title: t("common.NLS_SHARES"),
          path: "/shares",
          icon: <PeopleIcon fontSize="small" />,
        },
      ],
    },

    {
      title: t("common.NLS_INTERFACES"),
      items: [
        {
          title: t("api.NLS_API"),
          path: "/api",
          icon: <CodeIcon fontSize="small" />,
        },
      ],
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  //BUG: fixing this warning causes the side menu not to open anymore (!)
  //Line 188:6:  React Hook useEffect has missing dependencies: 'onMobileClose' and 'openMobile'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/account">
              {user.photo ? (
                <S3Image
                  img_key={user.photo}
                  img_width={48}
                  img_height={48}
                  img_radius={48}
                />
              ) : (
                <Avatar
                  sx={{
                    cursor: "pointer",
                    height: 32,
                    width: 32,
                  }}
                />
              )}
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {t("billing.NLS_USAGE_PLAN")}{" "}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                  underline="hover"
                >
                  {t(
                    findBluSensorDetailsNLS(
                      BluSensorUsagePlanDetails,
                      user.usage_plan,
                      ""
                    )
                  )}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {t("common.NLS_HELP")}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <Link
              color="primary"
              component="a"
              href="mailto:support@blusensor.com"
              underline="hover"
            >
              {t("common.NLS_CONTACT_US")}
            </Link>
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textSecondary" variant="body2">
            <Link
              color="primary"
              component="a"
              target="_blank"
              href="https://www.blusensor.com/datenschutz/"
              underline="hover"
            >
              {t("legal.NLS_LEGAL_PRIVACY")}
            </Link>
          </Typography>

          <Typography color="textSecondary" variant="body2" sx={{ pt: 1 }}>
            <Link
              color="primary"
              component="a"
              target="_blank"
              href="https://www.blusensor.com/agb/"
              underline="hover"
            >
              {t("legal.NLS_LEGAL_TERMS")}
            </Link>
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textSecondary" variant="caption">
            Version 1.7.10 (20240617)
          </Typography>
        </Box>

        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
