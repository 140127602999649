/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  Avatar
} from '@mui/material';

//COMPONENTS
import {
  findBluSensorDetailsNLS,
} from '../blusensor/BluSensor';

import {
  BluSensorUserRoleDetails
} from '../blusensor/BluSensorUser';

//HOOKS
import useAuth from '../../hooks/useAuth';

//ICONS
import UserIcon from '../../icons/User';

//COMPONENTS
import S3Image from '../aws/S3Image';

//NLS
import { useTranslation } from 'react-i18next';
import { logError } from '../log/Logger';

/**********************************************************************
* PAGE
***********************************************************************/
const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('nls');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      logError("AccountPopover", err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >

        {user.photo
          ? (
            <S3Image img_key={user.photo} img_width={32} img_height={32} img_radius={32} />
          )
          : (
            <Avatar
              sx={{
                cursor: 'pointer',
                height: 32,
                width: 32
              }}
            />

          )}


      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 270 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.name}
          </Typography>

          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {t(findBluSensorDetailsNLS(BluSensorUserRoleDetails, user.user_role, ""))}
          </Typography>

        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {t('account.NLS_EMAIL')}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {user.email}
          </Typography>


        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>


          <MenuItem
            component={RouterLink}
            to="/account"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t('account.NLS_ACCOUNT')}
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
