/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/

import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import toast from 'react-hot-toast';

//AWS AMPLIFY
import { Storage } from 'aws-amplify';

//TOOLS
import imageToBase64 from '../tools/ImageTools';
import { apiStorageGetFile } from '../blusensor/BluSensorStorageAPI';

/**********************************************************************
* S3 IMAGE
***********************************************************************/
const S3Image = (props) => {

  const { img_key, img_height, img_width, img_radius } = props;
  const [photo, setPhoto] = useState(null);

  //PHOTO: DOWNLOAD
  const getS3Image = useCallback(async () => {

    if (img_key != null && img_key.length > 0) {

      try {

        let file = await apiStorageGetFile(img_key);

        //setPhotoFile(file);

        const data = await imageToBase64(file.Body);
        setPhoto(data);

      } catch (err) {
        toast.error('Error getting photo!')
      }

    }

  }, [img_key]);

  //EFFECT
  useEffect(() => {
    getS3Image();
  }, [getS3Image]);

  let radius = 0.5;

  if (img_radius != null) {
    radius = img_radius;
  }

  if (photo != null) {
    return (
      <>

        <Box
          sx={{
            //cursor: 'pointer',
            backgroundImage: `url(${photo})`,
            backgroundPosition: 'center',
            backgroundColor: 'background.default',
            //backgroundColor: 'white',

            //backgroundSize: 'cover',

            //orginal dimensions
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',

            borderRadius: radius,
            height: img_height,
            width: img_width,

          }}
        />

      </>
    );
  }
  else {

    return (

      <Box
        sx={{
          //cursor: 'pointer', //mouse pointer
          backgroundImage: '/static/blusensor/empty.png',
          backgroundPosition: 'center',

          backgroundColor: 'background.default',
          //backgroundColor: 'white',

          //backgroundSize: 'cover',

          //orginal dimensions
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          borderRadius: radius,
          height: img_height,
          width: img_width
        }}
      />

    );
  }


};

S3Image.propTypes = {
  img_height: PropTypes.number.isRequired,
  img_width: PropTypes.number,
  img_key: PropTypes.string,
  img_radius: PropTypes.number
};

export default S3Image;
