
/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { Chip } from "@mui/material";
import { logDebug } from "../log/Logger";
import { findBluSensorDetails } from "./BluSensor";

/***************************************************************************
 * BluSensor User Roles
 ***************************************************************************/
const BluSensorUserRole = {

  //basic roles
  LIMITED: 0,
  STANDARD: 1,
  MANAGER: 2,
  ADMIN: 3,

  //extended roles
  DEVELOPER: 100,

}

const BluSensorUserRoleDetails = [
  { id: BluSensorUserRole.LIMITED, nls: 'account.NLS_ROLE_LIMITED' },
  { id: BluSensorUserRole.STANDARD, nls: 'account.NLS_ROLE_STANDARD' },
  { id: BluSensorUserRole.MANAGER, nls: 'account.NLS_ROLE_MANAGER' },
  { id: BluSensorUserRole.ADMIN, nls: 'account.NLS_ROLE_ADMIN' },
  { id: BluSensorUserRole.DEVELOPER, nls: 'account.NLS_ROLE_DEVELOPER' },

];


/***************************************************************************
 * BluSensor User Status
 ***************************************************************************/
const BluSensorUserStatus = {
  INACTIVE: 0,
  ACTIVE: 1,
  PAYMENT_PENDING: 2,
}

const BluSensorUserStatusDetails = [
  { id: BluSensorUserStatus.INACTIVE, nls: 'account.NLS_USER_INACTIVE' },
  { id: BluSensorUserStatus.ACTIVE, nls: 'account.NLS_USER_ACTIVE' },
  { id: BluSensorUserStatus.PAYMENT_PENDING, nls: 'account.NLS_USER_PAYMENT_PENDING' },
];


/***************************************************************************
 * BluSensor Usage Plans Features
 ***************************************************************************/
const BluSensorUsagePlanFeatures = {
  FREE: 0,
  CONFIG_PRO: 1,
  ORGANIZE: 2,
  FILES: 3,
  REPORTS: 4,
  SHARES: 5,
  API: 6,
  DOCFORMS: 7,
  DOCBOOKS: 8,
}


/***************************************************************************
 * BluSensor Usage Plans
 ***************************************************************************/
const BluSensorUsagePlan = {
  FREE: 0,
  BASIC: 1,
  STANDARD: 2,
  PRO: 3,
  ENTERPRISE: 4
}


const BluSensorUsagePlanDetails = [
  {
    id: BluSensorUsagePlan.FREE,
    nls: 'billing.NLS_USAGE_PLAN_FREE',
    features: [
      BluSensorUsagePlanFeatures.FREE
    ]
  },
  {
    id: BluSensorUsagePlan.BASIC,
    nls: 'billing.NLS_USAGE_PLAN_BASIC',
    features: [
      BluSensorUsagePlanFeatures.FREE,
      BluSensorUsagePlanFeatures.ORGANIZE
    ]
  },
  {
    id: BluSensorUsagePlan.STANDARD,
    nls: 'billing.NLS_USAGE_PLAN_STANDARD',
    features: [
      BluSensorUsagePlanFeatures.FREE,
      BluSensorUsagePlanFeatures.ORGANIZE
    ]
  },
  {
    id: BluSensorUsagePlan.PRO,
    nls: 'billing.NLS_USAGE_PLAN_PRO',
    features: [
      BluSensorUsagePlanFeatures.FREE,
      BluSensorUsagePlanFeatures.ORGANIZE,
      BluSensorUsagePlanFeatures.FILES,
      BluSensorUsagePlanFeatures.REPORTS,
      BluSensorUsagePlanFeatures.SHARES
    ]
  },
  {
    id: BluSensorUsagePlan.ENTERPRISE,
    nls: 'billing.NLS_USAGE_PLAN_ENTERPRISE',
    features: [
      BluSensorUsagePlanFeatures.FREE,
      BluSensorUsagePlanFeatures.ORGANIZE,
      BluSensorUsagePlanFeatures.FILES,
      BluSensorUsagePlanFeatures.REPORTS,
      BluSensorUsagePlanFeatures.SHARES,
      BluSensorUsagePlanFeatures.DOCBOOKS,
      BluSensorUsagePlanFeatures.DOCFORMS,
      BluSensorUsagePlanFeatures.API,
      //BluSensorUsagePlanFeatures.CONFIG_PRO,
    ]
  },
];





/***************************************************************************
 * BluSensor Usage Plans
 ***************************************************************************/
const BluSensorCustomer = {
  UNKNOWN: 0,
  PERSONAL: 1,
  BUSINESS: 2,
  PUBLIC: 3,
  SCHOOL: 4,
  UNIVERSITY: 5,
  INDUSTRIAL: 6
}


const BluSensorCustomerDetails = [
  { id: BluSensorCustomer.UNKNOWN, nls: 'customer.NLS_UNKNOWN' },
  { id: BluSensorCustomer.PERSONAL, nls: 'customer.NLS_PERSONAL' },
  { id: BluSensorCustomer.BUSINESS, nls: 'customer.NLS_BUSINESS' },
  { id: BluSensorCustomer.PUBLIC, nls: 'customer.NLS_PUBLIC' },
  { id: BluSensorCustomer.SCHOOL, nls: 'customer.NLS_SCHOOL' },
  { id: BluSensorCustomer.UNIVERSITY, nls: 'customer.NLS_UNIVERSITY' },
  { id: BluSensorCustomer.INDUSTRIAL, nls: 'customer.NLS_INDUSTRIAL' },

];

/***************************************************************************
 * BluSensor Usage Plans - Disclaimer
 ***************************************************************************/
const usagePlanShowUpgradeDisclaimer = (user, translation) => {

  if (user == null)
    return "";

  if (user.usage_plan >= BluSensorUsagePlan.PRO) {
    return (
      <>
        <Chip
          sx={{ mr: 1 }}
          label={translation("billing.NLS_USAGE_PLAN_FEATURE_INCLUDED")}
        />
      </>
    );
  } else {
    return (
      <>
        <Chip
          sx={{ mr: 1 }}
          label={translation("billing.NLS_USAGE_PLAN_UPGRADE_REQUIRED")}
        />
      </>
    );

  }

}

/***************************************************************************
 * BluSensor Usage Plans - Feature Check
 ***************************************************************************/
const usagePlanFeatureSupported = (user, feature) => {

  let planDetails = findBluSensorDetails(BluSensorUsagePlanDetails, user.usage_plan);

  if (planDetails === null || planDetails.features === null) {
    return false;
  }

  for (let planFeature of planDetails.features) {

    if (planFeature === feature) {
      return true;
    }

  }

  return false;
}


/***************************************************************************
 * MODULE EXPORTES
 ***************************************************************************/
export {
  BluSensorUserRole,
  BluSensorUserRoleDetails,
  BluSensorUserStatus,
  BluSensorUserStatusDetails,
  BluSensorCustomer,
  BluSensorCustomerDetails,
  BluSensorUsagePlan,
  BluSensorUsagePlanDetails,
  BluSensorUsagePlanFeatures,
  usagePlanShowUpgradeDisclaimer,
  usagePlanFeatureSupported
}