/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import Amplify, { Storage } from 'aws-amplify';

//UUID
import { v4 as uuid } from 'uuid';

import { logError } from '../../components/log/Logger';

//AWS AMPLIFY
import awsExports from "./../../aws-exports";
Amplify.configure(awsExports);

/***************************************************************************
 * STORAGE (S3): file extension
 ***************************************************************************/
const getFileExtension = (file) => {

  try {
    if (file != null && file.name != null) {
      return file.name.split(".").pop();
    }
    return null;
  } catch (err) {
    return null;
  }

}

/***************************************************************************
 * STORAGE (S3): file key
 ***************************************************************************/
const getUserStorageFileKey = (user_id, file) => {

  let file_extension = getFileExtension(file);

  let file_key;

  if (file_extension != null && file_extension.length > 0) {
    file_key = uuid() + "." + file_extension;
  } else {
    file_key = uuid();
  }

  return {
    key: file_key,
    path: 'iot/v1/files/' + user_id + "/uploads/" + file_key //without 'public'
  }

}
/***************************************************************************
 * STORAGE (S3): options
 ***************************************************************************/
const getUserStorageUploadOptions = (user_id, file) => {

  return {
    customPrefix: { public: 'public/iot/v1/files/' + user_id + "/uploads/" },
    metadata: {
      user_id: user_id,
      filename: file.name,
      ts_unix_ms: file.lastModified.toString()
    },
    contentType: file.type,
    cacheControl: "no-cache",
    contentDisposition: "attachment",

    resumable: false, //MUST BE SET TO FALSE (ListBucket=deny)

  };

}



/***************************************************************************
 * STORAGE (S3): upload
 ***************************************************************************/
async function apiStorageUploadFile(user_id, file) {

  try {

    //TODO: upload to shares?

    const fileKey = getUserStorageFileKey(user_id, file);

    await Storage.put(
      fileKey.key,
      file,
      getUserStorageUploadOptions(user_id, file)
    );

    //NOTE: result.key -> does not return full path 

    return fileKey;

  } catch (err) {
    logError("apiStorageUploadFile", err);
    return null;
  }

}

/***************************************************************************
 * STORAGE (S3): delete
 ***************************************************************************/
async function apiStorageDeleteFile(fileKey) {

  try {

    //NOTE: shared files can also be deleted

    await Storage.remove(fileKey);

  } catch (err) {
    logError("apiStorageDeleteFile", err);
  }


}

/***************************************************************************
 * STORAGE (S3): get
 ***************************************************************************/
async function apiStorageGetFile(fileKey) {

  try {

    //NOTE: shared files can be accessed

    let file = await Storage.get(fileKey, {
      cacheControl: "no-cache",
      download: true
    });

    return file;

  } catch (err) {
    logError("apiStorageGetFile", err);
  }

  return null;

}

/***************************************************************************
 * MODULE EXPORTES
 ***************************************************************************/
export {
  apiStorageUploadFile,
  apiStorageGetFile,
  apiStorageDeleteFile
}