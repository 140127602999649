/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography, Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//HOOKS
import gtm from '../lib/gtm';

//ICONS
import EngineeringIcon from '@mui/icons-material/Engineering';

//NLS
import { useTranslation } from 'react-i18next';

/**********************************************************************
 * PAGE 
***********************************************************************/
const MaintainanceMode = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('nls');

  //this.props.history.goBack();
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return <>
    <Helmet>
      <title> {t('infos.NLS_COMING_SOON')} | bluSensor&reg;</title>
    </Helmet>
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        minHeight: '100%',
        px: 3,
        py: '80px'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          color="textPrimary"
          variant={mobileDevice ? 'h4' : 'h2'}
        >
          {t('infos.NLS_MAINTAINANCE')}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 0.5 }}
          variant="subtitle1"
        >
          {t('infos.NLS_MAINTAINANCE_INFO')}
        </Typography>


        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <EngineeringIcon fontSize='large' />

        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >


          <Link
            color="primary"
            component="a"
            href="mailto:support@blusensor.com"
            underline="hover">
            support@blusensor.com
          </Link>

        </Box>

      </Container>
    </Box>
  </>;
};

export default MaintainanceMode;
