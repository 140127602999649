/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:9799cf57-e083-4161-8c93-a0b47860c4a2",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_pzsnP0MBu",
    "aws_user_pools_web_client_id": "7vn5u3f6k3j7l785r3udo8p5p6",
    "oauth": {
        "domain": "blusensor.auth.eu-west-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "blusensor-user-storage160445-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://5tnfm2upzfhanhc7bb6hg2nmey.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "maps": {
                "items": {
                    "blusensorapp-prod": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "blusensorapp-prod"
            }
        }
    },
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
