/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import nls_de from "./i18n/de/nls.json";
import nls_en from "./i18n/en/nls.json"
import nls_es from "./i18n/es/nls.json"
import nls_fr from "./i18n/fr/nls.json"
import nls_it from "./i18n/it/nls.json"

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      en: {
        nls: nls_en
      },
      de: {
        nls: nls_de
      },
      es: {
        nls: nls_es
      },
      fr: {
        nls: nls_fr
      },
      it: {
        nls: nls_it
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
