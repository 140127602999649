/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { Box } from '@mui/material';
import LogoColored from './LogoColored';

/**********************************************************************
 * PAGE
***********************************************************************/
const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    <LogoColored />
  </Box>
);

export default SlashScreen;
