/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/

/**********************************************************************
 * PAGE
***********************************************************************/
const Logo = (props) => (

  <img
    alt="Logo"
    height={64}
    src={'/static/logos/blusensor_logo_text_white.svg'}
  />
);


export default Logo;
