/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

//COMPONENTS
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';

//COMPONENTS
import Label from '../Label';

//LOGO
import Logo from '../Logo';

//ICONS
import MenuIcon from '../../icons/Menu';
import FitScreenRoundedIcon from '@mui/icons-material/FitScreenRounded';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

/**********************************************************************
* PAGE
***********************************************************************/

function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen().catch((e) => {
      console.error("Failed to enter fullscreen mode:", e);
    });
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((e) => {
        console.error("Failed to exit fullscreen mode:", e);
      });
    }
  }
}


const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>

        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
          size="large">
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                lg: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          />
        </RouterLink>


        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />

        <IconButton
          color="inherit"
          onClick={toggleFullScreen}
          size="large">
          <FitScreenRoundedIcon fontSize="medium" />
        </IconButton>

        <LanguagePopover />

        <Box sx={{ ml: 1 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
