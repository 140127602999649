/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import PropTypes from 'prop-types';
import { experimentalStyled } from '@mui/material/styles';

const LabelRoot = experimentalStyled('span')((({ theme, styleprops }) => {
  const backgroundColor = theme.palette[styleprops.color].main;
  const color = theme.palette[styleprops.color].contrastText;

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: theme.shape.borderRadius,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    //textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  };
}));

/**********************************************************************
 * PAGE
***********************************************************************/
const Label = (props) => {
  const { color = 'primary', children, ...other } = props;

  const styleprops = { color };

  return (
    <LabelRoot
      styleprops={styleprops}
      {...other}
    >
      {children}
    </LabelRoot>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
    'grey',
    'grey_light',
    'green',
    'theme_bg'
  ])
};

export default Label;
