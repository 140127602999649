/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import UsagePlanGuard from './components/UsagePlanGuard';
import LoadingScreen from './components/LoadingScreen';
import { BluSensorUsagePlanFeatures } from './components/blusensor/BluSensorUser';
import MaintainanceMode from './pages/MaintainanceMode';


const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);


//BLUSENSOR DASHBOARD
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));

//const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));

//BLUSENSOR ANALYTICS
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));

//BLUSENSOR REPORTS
const ReportList = Loadable(lazy(() => import('./pages/dashboard/ReportList')));
const ReportThings = Loadable(lazy(() => import('./pages/dashboard/ReportThings')));
const ReportStatistics = Loadable(lazy(() => import('./pages/dashboard/ReportStatistics')));

//BLUSENSOR ALARMS
const AlarmList = Loadable(lazy(() => import('./pages/dashboard/AlarmList')));
const AlarmDetails = Loadable(lazy(() => import('./pages/dashboard/AlarmDetails')));

//BLUSENSOR FACILITIES
const Facility = Loadable(lazy(() => import('./pages/dashboard/Facility')));
const FacilityList = Loadable(lazy(() => import('./pages/dashboard/FacilityList')));
const FacilityAddEdit = Loadable(lazy(() => import('./pages/dashboard/FacilityAddEdit')));
const FacilityDelete = Loadable(lazy(() => import('./pages/dashboard/FacilityDelete')));

//BLUSENSOR ASSETS
const Asset = Loadable(lazy(() => import('./pages/dashboard/Asset')));
const AssetList = Loadable(lazy(() => import('./pages/dashboard/AssetList')));
const AssetAddEdit = Loadable(lazy(() => import('./pages/dashboard/AssetAddEdit')));
const AssetDelete = Loadable(lazy(() => import('./pages/dashboard/AssetDelete')));

//BLUSENSOR THINGS
const Thing = Loadable(lazy(() => import('./pages/dashboard/Thing')));
const ThingList = Loadable(lazy(() => import('./pages/dashboard/ThingList')));
const ThingFind = Loadable(lazy(() => import('./pages/dashboard/ThingFind')));
const ThingAdd = Loadable(lazy(() => import('./pages/dashboard/ThingAdd')));
const ThingEdit = Loadable(lazy(() => import('./pages/dashboard/ThingEdit')));
const ThingDelete = Loadable(lazy(() => import('./pages/dashboard/ThingDelete')));

//BLUSENSOR DOCUMENT BOOKS
const DocBook = Loadable(lazy(() => import('./pages/dashboard/DocBook')));
const DocBookAddEdit = Loadable(lazy(() => import('./pages/dashboard/DocBookAddEdit')));
const DocBookDelete = Loadable(lazy(() => import('./pages/dashboard/DocBookDelete')));
const DocBookList = Loadable(lazy(() => import('./pages/dashboard/DocBookList')));
const DocBookSearch = Loadable(lazy(() => import('./pages/dashboard/DocBookSearch')));

//BLUSENSOR DOCUMENT FORMS
const DocForm = Loadable(lazy(() => import('./pages/dashboard/DocForm')));
const DocFormList = Loadable(lazy(() => import('./pages/dashboard/DocFormList')));
const DocFormAddEdit = Loadable(lazy(() => import('./pages/dashboard/DocFormAddEdit')));
const DocFormDelete = Loadable(lazy(() => import('./pages/dashboard/DocFormDelete')));

//BLUSENSOR FILES
const FileList = Loadable(lazy(() => import('./pages/dashboard/FileList')));

//BLUSENSOR SHARES
const UserSharesList = Loadable(lazy(() => import('./pages/dashboard/UserSharesList')));
const UserSharesAdd = Loadable(lazy(() => import('./pages/dashboard/UserSharesAdd')));
const UserSharesEdit = Loadable(lazy(() => import('./pages/dashboard/UserSharesEdit')));
const UserSharesUpdate = Loadable(lazy(() => import('./pages/dashboard/UserSharesUpdate')));


//BLUSENSOR INTEGRATIONS
const API = Loadable(lazy(() => import('./pages/dashboard/API')));

//BLUSENSOR USER
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));

//BLUSENSOR AUTHENTICATION
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

//BLUSENSOR ERRORS
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

//BLUSENSOR FEATURES
const ComingSoon = Loadable(lazy(() => import('./pages/ComingSoon')));

//BLUSENSOR PRICING
const UsagePlanPricing = Loadable(lazy(() => import('./pages/UsagePlanPricing')));
const UsagePlanRequest = Loadable(lazy(() => import('./pages/UsagePlanRequest')));
const UsagePlanUpgrade = Loadable(lazy(() => import('./pages/UsagePlanUpgrade')));

//BLUSENSOR CONTACT
const Contact = Loadable(lazy(() => import('./pages/Contact')));

//BLUSENSOR ROUTES
const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Dashboard />
        )
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.REPORTS}>
                <ReportList />
              </UsagePlanGuard>
            )
          },
          {
            path: 'things',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.REPORTS}>
                <ReportThings />
              </UsagePlanGuard>
            )

          },
          {
            path: 'statistics',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.REPORTS}>
                <ReportStatistics />
              </UsagePlanGuard>
            )
          }
        ]
      },


      /*
      {
        path: 'analytics',
        element: (
          <UsagePlanGuard>
            <Analytics />
          </UsagePlanGuard>
        )
      },
      */

      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'api',
        element: (
          <UsagePlanGuard feature={BluSensorUsagePlanFeatures.API}>
            <API />
          </UsagePlanGuard>
        )

      },
      {
        path: 'pricing',
        element: <UsagePlanRequest />
        //element: <UsagePlanPricing />
      },
      {
        path: 'upgrade',
        element: <UsagePlanUpgrade />
      },
      {
        path: 'alarms',
        children: [
          {
            path: '',
            element: <AlarmList />
          },
          {
            path: ':ts_iso',
            element: <AlarmDetails />
          },
        ]
      },
      {
        path: 'facilities',
        children: [
          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <FacilityList />
              </UsagePlanGuard>
            )
          },
          {
            path: 'add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <FacilityAddEdit editMode={false} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':facility_id',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <Facility />
              </UsagePlanGuard>
            )

          },
          {
            path: ':facility_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <FacilityAddEdit editMode={true} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':facility_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <FacilityDelete />
              </UsagePlanGuard>
            )

          },
        ]
      },
      {
        path: 'assets',
        children: [
          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <AssetList />
              </UsagePlanGuard>
            )

          },
          {
            path: 'add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <AssetAddEdit editMode={false} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':asset_id',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <Asset />
              </UsagePlanGuard>
            )

          },
          {
            path: ':asset_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <AssetAddEdit editMode={true} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':asset_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.ORGANIZE}>
                <AssetDelete />
              </UsagePlanGuard>
            )

          }

        ]
      }, {
        path: 'things',
        children: [
          {
            path: '',
            element: <ThingList />
          },
          {
            path: 'find',
            element: <ThingFind />
          },
          {
            path: ':serialnr/add',
            element: <ThingAdd />
          }
          ,
          {
            path: ':serialnr',
            element: <Thing />
          }
          ,
          {
            path: ':serialnr/edit',
            element: <ThingEdit />
          },
          {
            path: ':serialnr/delete',
            element: <ThingDelete />
          }
        ]
      },



      {
        path: 'files',
        children: [

          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.FILES}>
                <FileList />
              </UsagePlanGuard>
            )
          },

        ]
      },

      {
        path: 'docforms',
        children: [

          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCFORMS}>
                <DocFormList />
              </UsagePlanGuard>
            )

          },
          {
            path: 'add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCFORMS}>
                <DocFormAddEdit editMode={false} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':form_id',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCFORMS}>
                <DocForm />
              </UsagePlanGuard>
            )
          },
          {
            path: ':form_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCFORMS}>
                <DocFormDelete />
              </UsagePlanGuard>
            )
          },
          {
            path: ':form_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCFORMS}>
                <DocFormAddEdit editMode={true} />
              </UsagePlanGuard>
            )

          },
        ]
      },

      {
        path: 'docbooks',
        children: [

          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBookList />
              </UsagePlanGuard>
            )
          },
          {
            path: 'search',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBookSearch />
              </UsagePlanGuard>
            )

          },
          {
            path: 'add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBookAddEdit editMode={false} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBookAddEdit editMode={true} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBookDelete />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"add"} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/label/:systemLabel',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"list"} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/label/:systemLabel/:doc_id',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"view"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/:systemLabel/:doc_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"edit"} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/label/:systemLabel/:doc_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"delete"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/:systemLabel/:doc_id/versions',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"versions"} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/label/:systemLabel/:doc_id/versions/:ts_iso',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"version"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/custom/:customLabel/',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"list"} />
              </UsagePlanGuard>
            )

          },
          {
            path: ':book_id/label/custom/:customLabel/add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"add"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/custom/:customLabel/:doc_id',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"view"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/custom/:customLabel/:doc_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"edit"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/custom/:customLabel/:doc_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"delete"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/custom/:customLabel/:doc_id/versions',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"versions"} />
              </UsagePlanGuard>
            )
          },
          {
            path: ':book_id/label/custom/:customLabel/:doc_id/versions/:ts_iso',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.DOCBOOKS}>
                <DocBook action={"view"} />
              </UsagePlanGuard>
            )
          },
        ]
      },

      {
        path: 'shares',
        children: [

          {
            path: '',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.SHARES}>
                <UserSharesList />
              </UsagePlanGuard>
            )
          },

          {
            path: 'add',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.SHARES}>
                <UserSharesAdd />
              </UsagePlanGuard>
            )
          },
          {
            path: ':share_id/edit',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.SHARES}>
                <UserSharesEdit />
              </UsagePlanGuard>
            )
          },
          {
            path: ':share_id/accept',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.SHARES}>
                <UserSharesUpdate action="accept" />
              </UsagePlanGuard>
            )
          },
          {
            path: ':share_id/reject',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.SHARES}>
                <UserSharesUpdate action="reject" />
              </UsagePlanGuard>
            )
          },
          {
            path: ':share_id/delete',
            element: (
              <UsagePlanGuard feature={BluSensorUsagePlanFeatures.SHARES}>
                <UserSharesUpdate action="delete" />
              </UsagePlanGuard>
            )
          },


        ]
      },

      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }

];


//BLUSENSOR ROUTES (MAINTAINANCE)
const routesMaintainanceMode = [
  {
    path: '*',
    element: <MaintainanceMode />
  }

];

//PRODUCTION
export default routes;

//MAINTAINANCE MODE
//export default routesMaintainanceMode;
