/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/




/***************************************************************************
 * Find Map by ID
 * @param {list} list 
 * @param {id} id
 * @returns 
 ***************************************************************************/
const findBluSensorDetails = (list, id) => {
  return findBluSensorDetailsByProperty(list, id, "id");
}

/***************************************************************************
 * Find Map by property
 * @param {list} list 
 * @param {property} property
 * @returns 
 ***************************************************************************/
const findBluSensorDetailsByProperty = (list, id, property) => {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];

    if (item[property] === id) {
      return item;
    }
  }

  return null;
}


/***************************************************************************
 * Find Map TEXT by ID
 * @param {list} list 
 * @param {id} id
 * @param {defaultValue} defaultValue 
 * @returns 
 ***************************************************************************/

const findBluSensorDetailsText = (list, id, defaultValue) => {

  let item = findBluSensorDetails(list, id);
  if (item != null) {
    return item.text;
  }
  return defaultValue;
}


/***************************************************************************
 * Find Map NLS by ID
 * @param {list} list 
 * @param {id} id
 * @param {defaultValue} defaultValue 
 * @returns 
 ***************************************************************************/

const findBluSensorDetailsNLS = (list, id, defaultValue) => {

  let item = findBluSensorDetails(list, id);
  if (item != null) {
    return item.nls;
  }
  return defaultValue;
}

/***************************************************************************
 * Find Map ID by TEXT
 * @param {list} list 
 * @param {text} text 
 * @param {defaultValue} defaultValue 
 * @returns 
 ***************************************************************************/
const findBluSensorDetailsID = (list, text, defaultValue) => {

  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    if (item.text === text) {
      return item.id;
    }
  }
  return defaultValue;

}


/***************************************************************************
 * MODULE EXPORTES
 ***************************************************************************/
export {
  findBluSensorDetails,
  findBluSensorDetailsText,
  findBluSensorDetailsNLS,
  findBluSensorDetailsID,
  findBluSensorDetailsByProperty

}