/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UserUpdate!) {
    updateUser(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserShare = /* GraphQL */ `
  mutation AddUserShare($input: UserShareAdd!) {
    addUserShare(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserShare = /* GraphQL */ `
  mutation UpdateUserShare($input: UserShareUpdate!) {
    updateUserShare(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const acceptUserShare = /* GraphQL */ `
  mutation AcceptUserShare($input: UserShareAccept!) {
    acceptUserShare(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const rejectUserShare = /* GraphQL */ `
  mutation RejectUserShare($input: UserShareReject!) {
    rejectUserShare(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserShare = /* GraphQL */ `
  mutation DeleteUserShare($input: UserShareDelete!) {
    deleteUserShare(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserFacility = /* GraphQL */ `
  mutation AddUserFacility($input: UserFacilityAdd!) {
    addUserFacility(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserFacility = /* GraphQL */ `
  mutation UpdateUserFacility($input: UserFacilityUpdate!) {
    updateUserFacility(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserFacility = /* GraphQL */ `
  mutation DeleteUserFacility($input: UserFacilityDelete!) {
    deleteUserFacility(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserAsset = /* GraphQL */ `
  mutation AddUserAsset($input: UserAssetAdd!) {
    addUserAsset(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserAsset = /* GraphQL */ `
  mutation UpdateUserAsset($input: UserAssetUpdate!) {
    updateUserAsset(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserAsset = /* GraphQL */ `
  mutation DeleteUserAsset($input: UserAssetDelete!) {
    deleteUserAsset(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserFile = /* GraphQL */ `
  mutation AddUserFile($input: UserFileAdd!) {
    addUserFile(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserFile = /* GraphQL */ `
  mutation UpdateUserFile($input: UserFileUpdate!) {
    updateUserFile(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserFile = /* GraphQL */ `
  mutation DeleteUserFile($input: UserFileDelete!) {
    deleteUserFile(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserDocForm = /* GraphQL */ `
  mutation AddUserDocForm($input: UserDocFormAdd!) {
    addUserDocForm(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserDocForm = /* GraphQL */ `
  mutation UpdateUserDocForm($input: UserDocFormUpdate!) {
    updateUserDocForm(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserDocForm = /* GraphQL */ `
  mutation DeleteUserDocForm($input: UserDocFormDelete!) {
    deleteUserDocForm(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserDocBook = /* GraphQL */ `
  mutation AddUserDocBook($input: UserDocBookAdd!) {
    addUserDocBook(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserDocBook = /* GraphQL */ `
  mutation UpdateUserDocBook($input: UserDocBookUpdate!) {
    updateUserDocBook(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserDocBook = /* GraphQL */ `
  mutation DeleteUserDocBook($input: UserDocBookDelete!) {
    deleteUserDocBook(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserDoc = /* GraphQL */ `
  mutation AddUserDoc($input: UserDocAdd!) {
    addUserDoc(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserDoc = /* GraphQL */ `
  mutation UpdateUserDoc($input: UserDocUpdate!) {
    updateUserDoc(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserDoc = /* GraphQL */ `
  mutation DeleteUserDoc($input: UserDocDelete!) {
    deleteUserDoc(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserDocVersion = /* GraphQL */ `
  mutation AddUserDocVersion($input: UserDocVersionAdd!) {
    addUserDocVersion(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const registerUserThing = /* GraphQL */ `
  mutation RegisterUserThing($input: UserThingRegister!) {
    registerUserThing(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const addUserThing = /* GraphQL */ `
  mutation AddUserThing($input: UserThingAdd!) {
    addUserThing(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserThing = /* GraphQL */ `
  mutation UpdateUserThing($input: UserThingUpdate!) {
    updateUserThing(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const deleteUserThing = /* GraphQL */ `
  mutation DeleteUserThing($input: UserThingDelete!) {
    deleteUserThing(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserThingConfig = /* GraphQL */ `
  mutation UpdateUserThingConfig($input: UserThingConfigUpdate!) {
    updateUserThingConfig(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const updateUserThingConfigPro = /* GraphQL */ `
  mutation UpdateUserThingConfigPro($input: UserThingConfigProUpdate!) {
    updateUserThingConfigPro(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const sendUserThingCommand = /* GraphQL */ `
  mutation SendUserThingCommand($input: UserThingCommand!) {
    sendUserThingCommand(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const sendUserThingFirmwareUpdate = /* GraphQL */ `
  mutation SendUserThingFirmwareUpdate($input: UserThingFirmwareUpdate!) {
    sendUserThingFirmwareUpdate(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const publishUserThingTelemetry = /* GraphQL */ `
  mutation PublishUserThingTelemetry($input: UserThingTelemetryPublish!) {
    publishUserThingTelemetry(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
export const uploadUserThingTelemetry = /* GraphQL */ `
  mutation UploadUserThingTelemetry($input: UserThingTelemetryUpload!) {
    uploadUserThingTelemetry(input: $input) {
      success
      code
      message
      data
      __typename
    }
  }
`;
