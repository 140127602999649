/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { usagePlanFeatureSupported } from './blusensor/BluSensorUser';

/**********************************************************************
 * PAGE
***********************************************************************/

const UsagePlanGuard = ({ children, feature }) => {

  const { user } = useAuth();

  if (usagePlanFeatureSupported(user, feature)) {
    return <>{children}</>;
  } else {
    return <Navigate to="/upgrade" />;
  }

};

UsagePlanGuard.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.number
};

export default UsagePlanGuard;
