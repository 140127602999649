import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import { apiGetUser } from '../components/blusensor/BluSensorUserAPI';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state }),
  PASSWORD_CHANGE: (state) => ({ ...state }),
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  passwordChange: () => Promise.resolve(),
  reloadUser: () => Promise.resolve()
});


function getUserPayload(userCognito, user) {

  return ({
    //id: user.sub, //not part of object user
    id: userCognito.username,
    user_type: user.user_type,
    user_status: user.user_status,
    user_role: user.user_role,
    photo: user.photo,
    email: user.email,
    name: user.name,
    //email: userCognito.attributes.email,
    //name: userCognito.attributes.name,
    phone: user.phone,
    country: user.country,
    city: user.city,
    zip: user.zip,
    street: user.street,
    language: user.language,
    customer: user.customer,
    usage_plan: user.usage_plan,
    usage_price: user.usage_price,
    usage_period: user.usage_period,
    usage_expiry: user.usage_expiry,
    usage_contract: user.usage_contract,
    alarm_channel: user.alarm_channel,
    alarm_sensor: user.alarm_sensor,
    alarm_battery: user.alarm_battery,
    alarm_connection: user.alarm_connection,
    alarm_hysteresis: user.alarm_hysteresis,
    alarm_hardware: user.alarm_hardware,
    alarm_signal: user.alarm_signal,
    alarm_power: user.alarm_power,
    alarm_firmware: user.alarm_firmware,
    billing_name: user.billing_name,
    billing_company: user.billing_company,
    billing_country: user.billing_country,
    billing_city: user.billing_city,
    billing_street: user.billing_street,
    billing_vat: user.billing_vat,
    billing_currency: user.billing_currency,
    billing_zip: user.billing_zip,
    api_key: user.api_key,

  });
}

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {

        //get user info from AWS Cognito
        const userCognito = await Auth.currentAuthenticatedUser();

        let user = await apiGetUser(userCognito.username);

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: getUserPayload(userCognito, user)
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const userCognito = await Auth.signIn(email, password);

    if (userCognito.challengeName) {
      console.error(`Unable to login, because challenge "${userCognito.challengeName}" is mandated and we did not handle this case.`);
      return;
    }

    let user = await apiGetUser(userCognito.username);

    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        user: getUserPayload(userCognito, user)
      }
    });

  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password, name) => {
    await Auth.signUp({
      username: email,
      password,
      name,
      attributes: { email, name }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  const passwordChange = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    dispatch({
      type: 'PASSWORD_CHANGE'
    });
  };

  const reloadUser = async () => {

    const userCognito = await Auth.currentAuthenticatedUser();
    let user = await apiGetUser(userCognito.username);

    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user: getUserPayload(userCognito, user)
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        passwordChange,
        reloadUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
