/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';

//COMPONENTS
import AuthBanner from '../../components/authentication/AuthBanner';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT
} from '../../components/authentication/login';
import LogoColored from '../../components/LogoColored';

//HOOKS
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';

//ICONS
import IconSecurity from '@mui/icons-material/Security';

/**********************************************************************
 * PAGE 
***********************************************************************/
const Login = () => {
  const { platform } = useAuth();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return <>
    <Helmet>
      <title>Login | bluSensor&reg;</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <AuthBanner />
      <Container
        maxWidth="sm"
        sx={{ py: 5 }}
      >
        {/*
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 8
          }}
        >
          <RouterLink to="/">
            <LogoColored
              sx={{
                height: 192,
                width: 64
              }}
            />
          </RouterLink>
        </Box>
            */}
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3
              }}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  Log in
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  Log in to your bluSensor&reg; account
                </Typography>
              </div>
              <Box
                sx={{
                  height: 64,
                  '& > img': {
                    maxHeight: '100%',
                    width: 'auto'
                  }
                }}
              >

                <IconSecurity />

              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3
              }}
            >
              {platform === 'Amplify' && <LoginAmplify />}
              {platform === 'Auth0' && <LoginAuth0 />}
              {platform === 'Firebase' && <LoginFirebase />}
              {platform === 'JWT' && <LoginJWT />}
            </Box>
            <Divider sx={{ my: 3 }} />
            <Link
              color="textSecondary"
              component={RouterLink}
              to="/authentication/register"
              variant="body2"
              underline="hover">
              Create new account
            </Link>
            {platform === 'Amplify' && (
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/password-recovery"
                variant="body2"
                underline="hover">
                Forgot password
              </Link>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  </>;
};

export default Login;
