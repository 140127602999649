/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/

import useAuth from '../../hooks/useAuth';

import Amplify, { API, graphqlOperation } from 'aws-amplify';

import {
  getUser,
  getUserPublic
} from './../../graphql/queries'


//AWS AMPLIFY
import awsExports from "./../../aws-exports";
Amplify.configure(awsExports);

import { logError } from '../../components/log/Logger';
import { updateUser } from '../../graphql/mutations';


/***************************************************************************
 * API: USER
 ***************************************************************************/
//also see AmplifyContext.js

async function apiGetUser(user_id) {

  try {

    let apiResult = await API.graphql(graphqlOperation(getUser, { user_id: user_id }));

    return apiResult.data.getUser;

  } catch (err) {
    logError("getUser", err);
    return null;
  }
}


async function apiGetUserPublic(user_id) {

  try {

    let apiResult = await API.graphql(graphqlOperation(getUserPublic, { user_id: user_id }));
    return apiResult.data.getUserPublic;

  } catch (err) {
    logError("apiGetUserPublic", err);
    return null;
  }


}
async function apiUpdateUser(update) {



  try {
    await API.graphql(graphqlOperation(updateUser, { input: update }));
  } catch (err) {
    logError("apiUpdateUser", err);

  }

}

/***************************************************************************
 * MODULE EXPORTES
 ***************************************************************************/
export {
  apiGetUser,
  apiGetUserPublic,
  apiUpdateUser
}