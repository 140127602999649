/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/

const LOG_LEVEL_OFF = 0;
const LOG_LEVEL_ERROR = 1;
const LOG_LEVEL_WARNING = 2;
const LOG_LEVEL_INFO = 3;
const LOG_LEVEL_DEBUG = 4;


const LOGGER_LEVEL = LOG_LEVEL_ERROR;

//console.log('%cHello', 'color: green; background: yellow; font-size: 30px');

//TODO: log to AWS CloudWatch

const logDebug = (component, message) => {

    if (LOGGER_LEVEL >= LOG_LEVEL_DEBUG) {
        console.debug("DEBUG  " + " | " + component + " | " + message.toString());
    }

};

const logInfo = (component, message) => {

    if (LOGGER_LEVEL >= LOG_LEVEL_INFO) {
        console.info("INFO   " + " | " + component + " | " + message.toString());
    }

};

const logWarning = (component, message) => {

    if (LOGGER_LEVEL >= LOG_LEVEL_WARNING) {
        console.warn("WARNING" + " | " + component + " | " + message.toString());
    }

};

const logError = (component, message) => {

    if (LOGGER_LEVEL >= LOG_LEVEL_ERROR) {
        console.error("ERROR  " + " | " + component + " | " + JSON.stringify(message, null, 2));
        console.error(message);
    }

};

export {
    logDebug,
    logInfo,
    logWarning,
    logError

}
