/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import { Box, Chip, Container, Typography } from '@mui/material';

const AuthBanner = () => (
  <Box
    sx={{
      backgroundColor: 'background.paper',
      borderBottom: 1,
      borderColor: 'divider',
      py: 2
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            ml: 2,
            '& > img': {
              height: 30,
              mx: 2
            }
          }}
        >
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Welcome to <b>bluSensor&reg; Cloud</b>
          </Typography>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
